<template>
	<div class="databaseBackup">
		<div class="data-statistics">
			<div class="data-statistics-top">
				数据库备份管理
			</div>
			<div class="data-statistics-title">
				<div class="item">
					<div class="item-title">文章数</div>
					<div class="item-num">66</div>
				</div>
				<div class="item">
					<div class="item-title">文章数</div>
					<div class="item-num">66</div>
				</div>
				<div class="item">
					<div class="item-title">文章数</div>
					<div class="item-num">66</div>
				</div>
				<div class="item">
					<div class="item-title">文章数</div>
					<div class="item-num">66</div>
				</div>
				<div class="item">
					<div class="item-title">文章数</div>
					<div class="item-num">66</div>
				</div>
				<div class="item">
					<div class="item-title">文章数</div>
					<div class="item-num">66</div>
				</div>
			</div>
		</div>
		<div class="download">
			<div class="download-left">
				<div class="download-item">
					<div class="item-top">下载<span class="month">2022.07.01</span></div>
					<div class="item-content">
						<div class="number">33555</div>
						<div class="new-download">
							新下载{{ progressCount1 }}%
							<img src="@/assets/images/icon/smile.png" alt="" />
						</div>
						<el-progress
							v-if="progressCount1 !== 0"
							:percentage="progressCount1"
						></el-progress>
						<a href="#" download
							><div class="downloadBtn" @click="download1">下载</div></a
						>
					</div>
				</div>
				<div class="download-item">
					<div class="item-top">下载<span class="month">2022.07.01</span></div>
					<div class="item-content">
						<div class="number">33555</div>
						<div class="new-download">
							新下载{{ progressCount2 }}%
							<img src="@/assets/images/icon/smile.png" alt="" />
						</div>
						<el-progress
							v-if="progressCount2 !== 0"
							:percentage="progressCount2"
						></el-progress>
						<a href="#" download
							><div class="downloadBtn" @click="download2">下载</div></a
						>
					</div>
				</div>
				<div class="download-item">
					<div class="item-top">下载<span class="month">2022.07.01</span></div>
					<div class="item-content">
						<div class="number">33555</div>
						<div class="new-download">
							新下载{{ progressCount3 }}%
							<img src="@/assets/images/icon/smile.png" alt="" />
						</div>
						<el-progress
							v-if="progressCount3 !== 0"
							:percentage="progressCount3"
						></el-progress>
						<a href="#" download
							><div class="downloadBtn" @click="download3">下载</div></a
						>
					</div>
				</div>
				<div class="download-item">
					<div class="item-top">下载<span class="month">2022.07.01</span></div>
					<div class="item-content">
						<div class="number">33555</div>
						<div class="new-download">
							新下载{{ progressCount4 }}%
							<img src="@/assets/images/icon/smile.png" alt="" />
						</div>
						<el-progress
							v-if="progressCount4 !== 0"
							:percentage="progressCount4"
						></el-progress>
						<a href="#" download
							><div class="downloadBtn" @click="download4">下载</div></a
						>
					</div>
				</div>
				<div class="download-item">
					<div class="item-top">下载<span class="month">2022.07.01</span></div>
					<div class="item-content">
						<div class="number">33555</div>
						<div class="new-download">
							新下载{{ progressCount5 }}%
							<img src="@/assets/images/icon/smile.png" alt="" />
						</div>
						<el-progress
							v-if="progressCount5 !== 0"
							:percentage="progressCount5"
						></el-progress>
						<a href="#" download
							><div class="downloadBtn" @click="download5">下载</div></a
						>
					</div>
				</div>
			</div>
			<div class="download-right">
				<div class="download-item" v-if="downloadShow1">
					<div class="item-top">下载<span class="month">2022.07.01</span></div>
					<div class="item-content">
						<div class="number">33555</div>
						<div class="new-download">
							新下载50%
							<img src="@/assets/images/icon/smile.png" alt="" />
						</div>
						<el-progress :percentage="50"></el-progress>
						<a href="#" download><div class="downloadBtn">下载</div></a>
						<div class="downloadDel" @click="del1()">删除</div>
					</div>
				</div>
				<div class="download-item" v-if="downloadShow2">
					<div class="item-top">下载<span class="month">2022.07.01</span></div>
					<div class="item-content">
						<div class="number">33555</div>
						<div class="new-download">
							新下载50%
							<img src="@/assets/images/icon/smile.png" alt="" />
						</div>
						<el-progress :percentage="50"></el-progress>
						<a href="#" download><div class="downloadBtn">下载</div></a>
						<div class="downloadDel" @click="del2()">删除</div>
					</div>
				</div>
				<div class="download-item" v-if="downloadShow3">
					<div class="item-top">下载<span class="month">2022.07.01</span></div>
					<div class="item-content">
						<div class="number">33555</div>
						<div class="new-download">
							新下载50%
							<img src="@/assets/images/icon/smile.png" alt="" />
						</div>
						<el-progress :percentage="50"></el-progress>
						<a href="#" download><div class="downloadBtn">下载</div></a>
						<div class="downloadDel" @click="del3()">删除</div>
					</div>
				</div>
				<div class="download-item" v-if="downloadShow4">
					<div class="item-top">下载<span class="month">2022.07.01</span></div>
					<div class="item-content">
						<div class="number">33555</div>
						<div class="new-download">
							新下载50%
							<img src="@/assets/images/icon/smile.png" alt="" />
						</div>
						<el-progress :percentage="50"></el-progress>
						<a href="#" download><div class="downloadBtn">下载</div></a>
						<div class="downloadDel" @click="del4()">删除</div>
					</div>
				</div>
				<div class="download-item" v-if="downloadShow5">
					<div class="item-top">下载<span class="month">2022.07.01</span></div>
					<div class="item-content">
						<div class="number">33555</div>
						<div class="new-download">
							新下载50%
							<img src="@/assets/images/icon/smile.png" alt="" />
						</div>
						<el-progress :percentage="50"></el-progress>
						<a href="#" download><div class="downloadBtn">下载</div></a>
						<div class="downloadDel" @click="del5()">删除</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			downloadShow1: false,
			downloadShow2: false,
			downloadShow3: false,
			downloadShow4: false,
			downloadShow5: false,
			progressCount1: 0,
			progressCount2: 0,
			progressCount3: 0,
			progressCount4: 0,
			progressCount5: 0,
		}
	},
	created() {},
	mounted() {},
	methods: {
		download1() {
			if (this.downloadShow1 === false) {
				let clearInt = setInterval(() => {
					this.progressCount1++
					if (this.progressCount1 === 100) {
						this.downloadShow1 = true
						clearInterval(clearInt)
					}
				}, 20)
			} else {
				this.$message('已下载')
			}
		},
		del1() {
			this.progressCount1 = 0
			this.downloadShow1 = false
		},
		download2() {
			if (this.downloadShow2 === false) {
				let clearInt = setInterval(() => {
					this.progressCount2++
					if (this.progressCount2 === 100) {
						this.downloadShow2 = true
						clearInterval(clearInt)
					}
				}, 20)
			}
		},
		del2() {
			this.progressCount2 = 0
			this.downloadShow2 = false
		},
		download3() {
			if (this.downloadShow3 === false) {
				let clearInt = setInterval(() => {
					this.progressCount3++
					if (this.progressCount3 === 100) {
						this.downloadShow3 = true
						clearInterval(clearInt)
					}
				}, 20)
			}
		},
		del3() {
			this.progressCount3 = 0
			this.downloadShow3 = false
		},
		download4() {
			if (this.downloadShow4 === false) {
				let clearInt = setInterval(() => {
					this.progressCount4++
					if (this.progressCount4 === 100) {
						this.downloadShow4 = true
						clearInterval(clearInt)
					}
				}, 20)
			}
		},
		del4() {
			this.progressCount4 = 0
			this.downloadShow4 = false
		},
		download5() {
			if (this.downloadShow5 === false) {
				let clearInt = setInterval(() => {
					this.progressCount5++
					if (this.progressCount5 === 100) {
						this.downloadShow5 = true
						clearInterval(clearInt)
					}
				}, 20)
			}
		},
		del5() {
			this.progressCount5 = 0
			this.downloadShow5 = false
		},
	},
}
</script>

<style lang="scss" scoped>
.databaseBackup {
	background-color: #f0f3ef;
	.data-statistics {
		background-color: #fff;
		margin: 0 20px;
		border-radius: 5px;
		.data-statistics-top {
			height: 50px;
			border-bottom: 1px solid #d7d7d7;
			line-height: 50px;
			padding: 0 20px;
		}
		.data-statistics-title {
			display: flex;
			// background-color: aqua;
			justify-content: space-between;
			margin: 20px;
			padding-bottom: 20px;
			.item {
				background-color: #f0f3ef;
				padding: 20px;
				width: 280px;
				margin-left: 10px;
				border-radius: 5px;
				.item-title {
					color: #999;
					font-size: 14px;
				}
				.item-num {
					color: #005ea6;
					font-size: 24px;
				}
			}
			.item:first-child {
				margin-left: 0;
			}
		}
	}
	.download {
		margin: 20px 20px 0;
		border-radius: 5px;
		display: flex;
		.download-left {
			margin-right: 20px;
			padding: 20px;
			border-radius: 5px;
			background-color: #fff;
		}
		.download-right {
			background-color: #fff;
			border-radius: 5px;
			padding: 20px;
			min-width: 440px;
		}
		.download-item {
			background-color: #fff;
			// border: 1px solid blue;
			margin-top: 20px;
			border-radius: 5px;
			width: 400px;
			border: 1px solid #d7d7d7;
			position: relative;
			.item-top {
				height: 50px;
				border-bottom: 1px solid #d7d7d7;
				padding: 0 20px;
				line-height: 50px;
				background-color: #f0f3ef;
				border-top-right-radius: 5px;
				border-top-left-radius: 5px;
				.month {
					display: inline-block;
					min-width: 30px;
					height: 30px;
					margin-left: 5px;
					background-color: #005ea6;
					border-radius: 5px;
					text-align: center;
					line-height: 30px;
					color: #fff;
          padding: 0 5px;
				}
			}
			.item-content {
				padding: 20px;
				.new-download {
					margin: 5px 0;
					img {
						width: 18px;
						height: 18px;
						margin-left: 5px;
						margin-top: -4px;
					}
				}
				.downloadBtn {
					cursor: pointer;
					position: absolute;
					right: 20px;
					top: 15px;
				}
				.downloadBtn:hover {
					color: #005ea6;
				}
				.downloadDel {
					cursor: pointer;
					position: absolute;
					right: 60px;
					top: 15px;
				}
				.downloadDel:hover {
					color: #005ea6;
				}
			}
			::v-deep {
				.el-progress-bar__inner {
					background-color: #005ea6;
				}
			}
		}
		.download-item:first-child {
			margin-top: 0;
		}
	}
}
</style>
